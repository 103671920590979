<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      tools: null,
      codes: {
        modal: false,
        name: '',
        list: [],
      },
    };
  },
  methods: {
    getTools() {
      api
        .get('tools')
        .then(response => {
          if (response.data.status == 'success') {
            this.tools = response.data.list
          }
        })
    },
    showCodes(row) {
      this.codes.modal = false;
      this.codes.modal = true;
      this.codes.name = row.name;
      this.codes.list = row.codes;
    }
  },
  mounted() {
    this.getTools();
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('Ferramentas') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!tools" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="tools && tools.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4" v-for="(row, index) in tools" :key="index">
        <div class="card">
          <div class="card-body">
            <div class="tool-title d-flex justify-content-end align-items-center text-uppercase" :class="'tool-' + row.id">
              <img class="tool-image" :src="require('@/assets/images/plans/' + row.image)" />
              <h3 class="ml-2 mb-0 font-size-26 text-white">{{ row.name }}</h3>
            </div>
            <div v-html="row.description"></div>
            <div class="mt-3">
              <div v-if="row.status == 'active'">
                <a class="btn btn-outline-success btn-lg btn-block" target="_blank" :href="row.url.panel">ACESSAR</a>
                <a class="btn btn-outline-default btn-lg btn-block" target="_blank" :href="row.url.register">CADASTRAR</a>
                <p class="text-center mt-2 font-size-14">Atenção: Ao criar sua conta informe o mesmo email de cadastro.</p>
              </div>
              <div v-else>
                <button class="btn btn-outline-dark btn-lg btn-block" disabled>INATIVO</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="codes.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="text-center pb-5">
        <h3 class="mb-4">{{ codes.name }}</h3>
        <hr class="mb-5">
        <div v-for="(row, index) in codes.list" :key="index" class="d-flex flex-gap-10 justify-content-between align-items-center mb-3">
          <div class="d-flex flex-gap-10 align-items-center">
            <h5 class="mb-0">{{ row.code }}</h5>
          </div>
          <span v-if="row.status == 'available'" class="badge badge-success font-size-12">DISPONÍVEL</span>
          <div class="d-flex flex-gap-10 align-items-center">
            <button class="border-0 bg-white" v-clipboard:copy="row.code" v-on:click="$toast.success('Código copiado!')">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #31304F;">
                <path
                  d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
                </path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <a class="btn btn-outline-dark btn-lg btn-block " v-on:click="codes.modal = false;">Fechar <sup>ESC</sup></a>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.tool-title {
  position: relative;
  border-radius: 10px;
  margin: 10px 0 40px;
  padding: 15px 20px;
}

.tool-easyleads {
  background: rgb(55, 128, 38);
  background: linear-gradient(180deg, rgba(55, 128, 38, 1) 0%, rgba(32, 71, 26, 1) 100%);
}

.tool-meetleads {
  background: rgb(3, 77, 108);
  background: linear-gradient(180deg, rgba(3, 77, 108, 1) 0%, rgba(5, 45, 64, 1) 100%);
}

.tool-image {
  position: absolute;
  top: -20px;
  left: 20px;
  width: 90px;
}
</style>